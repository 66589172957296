// Utils

.bg-primary-dark{
    background: #9ECD68;
}

.icon-lg{
    width: 50px;
    height: auto;
}
// Navbar

.navbar{
    .navbar-brand{
        img{
            max-width: 140px;
        }
    }
    .navbar-nav{
        .nav-item{
            font-weight: 700;
            font-size: .8125rem;
            text-transform: uppercase;
            .dropdown {
                .dropdown-item {
                    font-size: 13px;
                    text-transform: none;
                    font-weight: 700;
                }
            }
            @include media-breakpoint-up(md) {
                margin-left: .1rem;
                margin-right: .1rem;
            }
            @include media-breakpoint-up(xl) {
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }
    }
    .btn-pen, .btn-locker{
        background-position: left 14px center;
        background-repeat: no-repeat;
        padding: .35rem 1.2rem .35rem 2.1rem;
    }
    .btn-pen{
        background-size: 14px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAABQElEQVR4AZTTA2wsARSF4Web8Quebatx3aAMasTZ2Jm4sbdxYzVqo9q2bdvW7b/JGoMk3+7MnDtnfEpRFF2iY6IvYBI3vOVGiqqxB8E7w0XWEgUH8IMCgclQETv8gyDNaVsYNpCHs9Yi1ZI7EGR4yV5hHAe4olZyFmsow3kv+SPMYNCyrlZUiDHc95LdRDeWVC+NMAuH+OnjTPOw7/wqeCt5CMEBevDSLTdD8N6yrlZUi1F8wRA2EW3NTBAkq75HDARAEGddv4VsCIohyFR9sxk4jTb04Zzb9hwI2nFaqygKgii378sMQRkuWTMPzk+iH304Y932BI0QpNu2axXFQbCPCKRhE4sItQ6rspScQTfaUAKxysfJpBGKCRvkD9W4A5QIoZkxBaaAFIMmAvFfkLegbCHcGgiHUSwQq4HY5GIAwtkP3L6oacgAAAAASUVORK5CYII=')
    }
    .btn-locker{
        background-size: 11px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAQAAAD4MpbhAAAA/klEQVR42nXRvy8DYBCH8ZNODDbxB0jNDYtJLAY/IoRBmlgMjbAxWIiOLBhMpDF2EonG3lhJiEGCEJOhupmI+hhaLarPTZd7Lt/3zUXU0C/nyYeKZ0fGtcVPrPsEr0qqnOpsjOfBtUGJCEkFcPw9bveCkq76QsI5SFXbabD1K3IZzFebPTD2S1gCUxFhFVSUPbhSrNWZO7cWI8IF3lT8z0YooxShQ7ekVL3mwGEoQuMH9VcMg92wDwaahEmQDWtgtoWwGRbAUpMw+h0xC9abhAGQa2T9FXrBSRgBjwryDuzIWpGRNgMuQ8K91rxWb9ejz5AJaRnLsrbty7vx7vILR1VQXDlERxkAAAAASUVORK5CYII=');
    }
}

.navbar-navigation{
    border-bottom: 1px solid $light;
    overflow-x: auto;
    @include media-breakpoint-up(lg) {
        border-color: transparent;
    }
    .nav-item{
        display: block;
        text-align: center;
        padding: .8rem;
        border-radius: 4rem;
        background: $light;
        color: $dark;
        font-weight: $font-weight-bold;
        text-decoration: none;
        font-size: .8rem;
        @include media-breakpoint-up(lg) {
            font-size: 1rem;
            min-width: 220px;
        }
        &.active{
            background: $secondary;
            color: $white;
        }
        transition: all .2s ease-in-out;
        &:hover{
            background: darken($light, 5%);
            &.active{
                background: $secondary;
            }
        }
    }
}

// Buttons

.btn{
    &:focus, &:active{
        box-shadow: none !important;
    }
    border-radius: 2rem !important;
}

.btn.btn-primary{
    color: #fff;
    &:hover{
        color: #fff;
    }
    &:focus{
        color: #fff;
    }
}

.btn.btn-secondary{
    color: #fff;
    &:hover{
        color: #fff;
    }
}

.btn-sm.btn-primary{
    background-color: $terciary;
    border-color: $terciary;
}


// Header

header{
    .title{
        padding: 6px 0 0 0;
    }
    .user-avatar{
        width: 70px;
        height: 70px;
        border-radius: 35px;
        object-fit: cover;
        object-position: center;
    }
    .thumbs{
        width: 18px;
        height: auto;
        @include media-breakpoint-up(md) {
            width: 26px;
        }
        &.empty{
            opacity: .2;
        }
    }
}

// Title

.title-section{
    img{
        width: 30px;
        margin-right: 1rem;
        height: auto;
        &.icon-lg{
            width: 50px;
        }
    }
    h3.text{
        font-weight: $font-weight-bold;
        font-size: 110%;
    }
}

// Hashtag-button

.hashtag-btn{
    background: $light;
    color: $dark;
    font-weight: $font-weight-bold;
    text-decoration: none;
    width: 100%;
    border-radius: 2rem;
    padding: .8rem 1.5rem;
    display: block;
    transition: all .2s ease-in-out;
    &:hover{
        background: darken($light, 5%);
    }
}


// Form control

.form-control{
    padding: .8rem 1.5rem !important;
    border-radius: 2rem !important;
    &.form-control-icon{
        padding: .8rem 1.5rem .8rem 3rem !important;
        background-repeat: no-repeat;
        background-position: center left 16px;
        background-size: 22px;
        &.hashtag{
            background-image: url('data:image/svg+xml;utf8, <svg height="29" viewBox="0 0 29 29" width="29" xmlns="http://www.w3.org/2000/svg"><path d="m1012.124 910.7227c.104-1.269.167-2.526.207-3.783 1.148.086 2.29.143 3.427.179-.035 1.246-.093 2.502-.179 3.76-1.148-.029-2.302-.081-3.455-.156m12.545-.022c-1.234.074-2.475.126-3.721.161.105-1.252.174-2.492.214-3.732 1.546-.035 3.086-.093 4.626-.174 1.436-.074 2.416-.749 2.07-2.941-.438-2.774-2.03-2.555-3.189-2.485-1.159.069-2.318.121-3.479.155-.034-1.62-.097-3.235-.178-4.845-.075-1.436-.75-2.417-2.942-2.07-2.774.438-2.554 2.03-2.485 3.189.074 1.246.126 2.497.161 3.755-1.131-.029-2.261-.075-3.397-.15-.035-1.58-.098-3.155-.173-4.724-.075-1.436-.75-2.417-2.942-2.07-2.774.438-2.555 2.03-2.486 3.189.064 1.021.109 2.048.145 3.075-1.483-.197-2.965-.427-4.464-.716-1.143-.225-2.383-.265-2.671 1.344-.191 1.898.974 3.403 2.999 3.871 1.407.322 2.798.587 4.187.801-.023 1.281-.081 2.573-.161 3.865-1.448-.197-2.895-.415-4.354-.705-1.143-.224-2.383-.264-2.671 1.344-.191 1.899.974 3.404 2.999 3.87 1.188.272 2.365.502 3.536.699-.202 1.591-.45 3.195-.768 4.804-.224 1.142-.265 2.382 1.344 2.671 1.898.19 3.403-.975 3.87-3 .295-1.28.537-2.555.744-3.824 1.183.104 2.36.168 3.531.214-.185 1.309-.387 2.618-.647 3.939-.224 1.142-.264 2.382 1.344 2.671 1.898.19 3.403-.975 3.871-3 .271-1.187.501-2.37.697-3.547 1.846-.029 3.68-.097 5.515-.19 1.435-.075 2.416-.75 2.07-2.942-.444-2.792-2.036-2.572-3.195-2.502" fill="%239fce67" fill-rule="evenodd" transform="translate(-999 -894)"/></svg>');
        }
    }
}

// Trip Card

.list-of-trips{
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
}

.card-trip{
    .col, .col-auto{
        padding: 0;
    }
    .img-wrapper{
        width: 30px;
        text-align: center;
        margin-right: 12px;
        img{
            max-width: 28px;
        }
    }
    .btn-primary{
        font-size: 80%;
    }

}

.btn-choice{
    text-align: center;
    white-space: nowrap;
}

.address-search-wrapper{
    position: relative;
    .result-list{
        position: absolute;
        top: 98%;
        list-style: none;
        background: #fff;
        padding-left: 0;
        width: 100%;
        border: 2px solid $gray-300;
        li{
            cursor: pointer;
            padding: .7rem 1rem;
            width: 100%;
            transition: all .2s ease-in-out;
            &:hover{
                background: $gray-200;
            }
            &:not(:last-child){
                border-bottom: 1px solid $gray-300;
            }
        }
    }
}


// Login

.login{
    min-height: 96vh;
}

.fc-toolbar-title {
    color: $primary;
    text-transform: uppercase;
    font-size: 1.4em !important;
    font-weight: bold;
}

.fc-button.fc-button-primary {
    background-color: $primary;
    border: $primary;
    font-weight: bold;
    &:hover {
        background-color: $primary;
        border: $primary;
        font-weight: bold;
    }
}

.fc-dayGridWeek-button, .fc-dayGridMonth-button {
    background-color: #F2F2F2 !important;
    border: #F2F2F2 !important;
    color: #515353 !important;
    &.fc-button-active {
        background-color: $primary !important;
        border: $primary !important;
        color: #ffffff !important;
    }
}

.plus-menu-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
}

/* FullCalendar Tooltips */
.popper,
.tooltip {
    position: absolute;
    z-index: 9999;
    color: white;
    width: 250px;
    border-radius: 3px;
    /*box-shadow: 0 0 2px rgba(0,0,0,0.5);*/
    text-align: center;
    opacity: 1;
}
.style5 .tooltip {
    background: $primary;
    color: #FFFFFF;
    max-width: 300px;
    width: auto;
    font-size: 0.9rem !important;
    padding: .5em 1em;
}

.tooltip-inner {
    font-size: 0.9rem !important;
    max-width: 300px !important;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: $primary;
    border-radius: 0.25em;
}

.popper .popper__arrow,
.tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
}

.tooltip .tooltip-arrow,
.popper .popper__arrow {
    border-color: $primary;
}
.style5 .tooltip .tooltip-arrow {
    border-color: $primary;
}
.popper[x-placement^="top"],
.tooltip[x-placement^="top"] {
    margin-bottom: 5px;
}
.popper[x-placement^="top"] .popper__arrow,
.tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
.popper[x-placement^="bottom"],
.tooltip[x-placement^="bottom"] {
    margin-top: 5px;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow,
.popper[x-placement^="bottom"] .popper__arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}
.tooltip[x-placement^="right"],
.popper[x-placement^="right"] {
    margin-left: 5px;
}
.popper[x-placement^="right"] .popper__arrow,
.tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}
.popper[x-placement^="left"],
.tooltip[x-placement^="left"] {
    margin-right: 5px;
}
.popper[x-placement^="left"] .popper__arrow,
.tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}
