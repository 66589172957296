@import 'assets/fonts/Qanelas/stylesheet.css';
@import 'assets/fonts/Local-Brewery-Two/stylesheet.css';


body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.title{
    font-family:"Local Brewery Two Bold";
    -webkit-text-stroke-width: 0.2px;
}